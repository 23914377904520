<template>
    <ly-dialog :title="loadingTitle" v-model="dialogVisible" width="50%" :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
        <div>
            <video :src="src" controls style="width:100%;height:360px"></video>
        </div>
    </ly-dialog>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "lyVideoPlayer",
        components: {LyDialog},
        props:{
        },
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                src:""
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('closed')
            },
            moduleFn(data,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.src = data
            },
        }
    }
</script>

<style scoped>

</style>
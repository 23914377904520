<template>
    <div>
        <ly-dialog :title="loadingTitle" v-model="dialogVisible" width="560px" center :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="left" label-width="auto">
                <el-form-item label="摊位介绍：" prop="desc">
                    <el-input v-model="formData.desc" :rows="3" type="textarea" placeholder="请输入" :disabled="true" />
                </el-form-item>
                <el-form-item>
                    <el-image v-for="item in formData.images" :preview-src-list="[item]" :preview-teleported="true" :src="item" style="width: 39px;height: 39px;margin-right:10px;"></el-image>
                </el-form-item>
                <el-form-item label="联系人：" prop="contacts">
                    {{formData.contacts}}
                </el-form-item>
                <el-form-item label="联系方式：" prop="contact_mobile">
                    {{formData.contact_mobile}}
                </el-form-item>
                <el-form-item label="微信号：" prop="weixin">
                    {{formData.weixin}}
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">关闭</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    export default {
        components: {LyDialog},
        name: "addModuleStall",
        emits: ['refreshData','closed'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                formData:{
                    desc:'',
                    contacts:'',
                    contact_mobile:'',
                    weixin:'',
                    images:[],
                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('closed')
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = deepClone(item)
                }
            },
            submitData() {
                // this.$refs['rulesForm'].validate(obj=>{
                //     if(obj) {
                //         this.loadingSave=true
                //         let param = {
                //             ...this.formData
                //         }
                //         if(this.formData.id){
                //             apiSystemUserEdit(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }else{
                //             apiSystemUserAdd(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }

                //     }
                // })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(option) {
                // OSS.ossUploadProductImg(option);
            },
            imgUploadSuccess(res) {
                if (res) {
                    this.formData.img = res.url
                }
            },
        }
    }
</script>


<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="昵称：">
                                <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable placeholder="昵称" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号：">
                                <el-input v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="状态：">
                                <el-select size="default" v-model="formInline.status" placeholder="请选择" clearable style="width:100%" @change="search">
                                    <el-option
                                            v-for="item in statusList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="店铺名称：">
                                <el-input v-model.trim="formInline.name" maxlength="60"  clearable placeholder="店铺名称" @change="search" style="width:100%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="店铺类型：">
                                <el-select size="default" v-model="formInline.type" placeholder="请选择" clearable filterable style="width:100%" @change="search">
                                    <el-option
                                            v-for="item in typeList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="到期时间：">
                                <el-date-picker
                                        style="width:100%"
                                        v-model="timers"
                                        type="datetimerange"
                                        @change="timeChange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button>
                                <el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
                            </el-form-item>
                        </el-col> 
                    </el-row>
                </div>
                    
                <!-- <el-form-item label="" v-show="hasPermission(this.$route.name,'Delete')">
                    <el-button @click="handleDelete" type="danger" :disabled="multiple" >批量删除</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>展示中店铺数量：{{orderstatics.totalUp}}</li>
            <li>已到期店铺数量：{{orderstatics.totalDown}}</li>
            <li>累计店铺数量：{{orderstatics.totalNums}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%"  @selection-change="handleSelectionChange">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column type="selection" width="55" align="center" disabled='false'></el-table-column>
            <el-table-column width="70" prop="id" label="店铺ID"></el-table-column>
            <el-table-column min-width="70" prop="avatar" label="头像">
                <template #default="scope">
                    <el-image :preview-src-list="[scope.row.userinfo.avatar]" :preview-teleported="true" :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 39px;height: 39px" :onerror="defaultImg"></el-image>
                </template>
            </el-table-column>
            <el-table-column min-width="110" prop="userinfo.nickname" label="昵称"></el-table-column>
            <el-table-column min-width="120" prop="userinfo.mobile" label="账号"></el-table-column>
            <el-table-column width="130" prop="name" label="店铺名称"></el-table-column>
            <el-table-column width="90" prop="typename" label="店铺类型"></el-table-column>
            <el-table-column min-width="250" prop="position" label="位置" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="70" prop="avatar" label="店铺主图">
                <template #default="scope">
                    <el-image :preview-src-list="[scope.row.cover]" :preview-teleported="true" :src="scope.row.cover" style="width: 39px;height: 39px"></el-image>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="images" label="轮播图">
                <template #default="scope">
                    <el-image v-for="item in scope.row.images" :preview-src-list="[item]" :preview-teleported="true" :src="item" style="width: 39px;height: 39px;margin-right:10px;"></el-image>
                </template>
            </el-table-column> -->
            <el-table-column min-width="160" prop="business_hours" label="营业时间"></el-table-column>
            <el-table-column min-width="160" prop="expire_time" label="到期时间"></el-table-column>
            <el-table-column width="90" prop="statusname" label="状态"></el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'Detail')">查看详情</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <AddModuleShop ref="addModuleFlag" @refreshData="getData" v-if="isDetailDialogShow" @closed="isDetailDialogShow=false"></AddModuleShop>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {tcliveShopList,tcliveShopListDelete,tcliveShopListOrderstatistics,tcliveShoptype} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import AddModuleShop from "./components/addModuleShop";
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            AddModuleShop
        },
        name:'tcShopManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
                statusList:[
                    {id:0,name:'展示中'},
                    {id:1,name:'已到期'}
                ],
                typeList:[
                ],
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                isDetailDialogShow:false,
                orderstatics:{
                    totalUp: 0,
                    totalDown: 0,
                    totalNums:0
                },
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return tcliveShopListDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            //订单金额统计获取
            getOrderstatistics(){
                tcliveShopListOrderstatistics(this.formInline).then(res => {
                     if(res.code ==2000) {
                        this.orderstatics = res.data
                     }
                 })
            },
            //订单金额统计获取
            getTypeList(){
                tcliveShoptype({page:1,limit:999}).then(res => {
                     if(res.code ==2000) {
                        this.typeList = res.data.data
                     }
                 })
            },
            addModule() {
                this.$refs.addModuleFlag.addModuleFn(null,'新增')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='Detail') {
                    this.isDetailDialogShow = true
                    this.$nextTick(()=>{
                        vm.$refs.addModuleFlag.addModuleFn(row,'详情')
                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }else if(flag=='delete') {
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        tcliveShopListDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
                this.getOrderstatistics()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
                this.getOrderstatistics()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                tcliveShopList(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
            if(!!history.state.mobile){
                this.formInline.mobile = history.state.mobile
            }
            this.getData()
            this.getOrderstatistics()
            this.getTypeList()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>
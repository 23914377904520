<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="昵称：">
                    <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable placeholder="昵称" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="套餐名称：">
                    <el-input v-model.trim="formInline.package_name" maxlength="60"  clearable placeholder="套餐名称" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="开通时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="exportDataFrontend" :disabled="loadingPage">导出</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>累计收入：¥{{orderstatics.totalmoney}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column min-width="80" prop="userinfo.id" label="用户ID"></el-table-column>
            <el-table-column min-width="70" prop="userinfo.avatar" label="头像">
                <template #default="scope">
                    <el-image :preview-src-list="[scope.row.userinfo.avatar]" :preview-teleported="true" :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg" ></el-image>
                </template>
            </el-table-column>
            <el-table-column min-width="110" prop="userinfo.nickname" label="昵称"></el-table-column>
            <el-table-column min-width="110" prop="userinfo.mobile" label="账号"></el-table-column>
            <el-table-column min-width="180" prop="package_name" label="会员套餐名称"></el-table-column>
            <el-table-column min-width="150" prop="total_amount" label="价格">
                <template #default="scope">
                    <span>¥{{scope.row.total_amount}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="pay_method" label="支付方式"></el-table-column>
            <el-table-column min-width="150" prop="order_no" label="订单编号"></el-table-column> -->
            <el-table-column min-width="180" prop="pay_time" label="开通时间"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {tcliveShopVipOrder,tcliveShopVipOrderOrderstatistics} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
        },
        name:'shopVipStatistics',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    name:'',
                    type:3,
                    page: 1,
                    limit: 10,
                },
                orderstatics:{
                    totalmoney: 0,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            addModule() {
                this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    vm.$refs.addModuleFlag.addModuleFn(row,'详情','1')
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
                this.getOrderstatistics()
            },
            //订单金额统计获取
            getOrderstatistics(){
                tcliveShopVipOrderOrderstatistics(this.formInline).then(res => {
                     if(res.code ==2000) {
                        this.orderstatics = res.data.data
                     }
                 })
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                tcliveShopVipOrder(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
             //前端直接导出(data)
             exportDataFrontend(){
                const columns = this.$refs.tableref.store.states.columns._rawValue;
                // console.log(columns)
                let tableData = [
                    ['用户ID','头像', '昵称','账号', '会员套餐名称',"价格","开通时间"]//导出表头
                ] // 表格表头
                this.tableData.forEach ((item,index)=> {
                    let rowData = []
                    //导出内容的字段
                    rowData = [
                        item.userinfo.id,
                        item.userinfo.avatar,
                        item.userinfo.nickname,
                        item.userinfo.mobile,
                        item.package_name,
                        item.total_amount,
                        item.pay_time,
                    ]
                    tableData.push(rowData)
                })
                let wb = XLSX.utils.book_new()
                let ws = XLSX.utils.aoa_to_sheet(tableData)
                // 设置单元格宽度
                const columnWidths = columns.map(column => ({ wch: !!column.width?column.width/6:column.minWidth/6 }));
                ws['!cols'] = columnWidths;
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1') // 工作簿名称
                // 将工作簿转换为二进制对象
                const excelBuffer = XLSX.write(wb, {
                    type: 'array',
                    bookType: 'xlsx',
                });

                // 将二进制对象转换为 Blob 对象
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

                // 保存 Excel 文件
                const fileName = '导出店铺会员流水.xlsx';
                if (navigator.msSaveBlob) {
                    // 兼容 IE 10+
                    navigator.msSaveBlob(data, fileName);
                } else {
                    // 使用 FileSaver.js（推荐）
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                }
            },
            //前端直接导出
            exportDataFrontend2(){
                //分页情况先显示所有数据(在第一页)
                var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
                const $table = this.$refs.tableref.$el
                const wb = XLSX.utils.table_to_book($table, xlsxParam);//table_to_book放入的是table 的DOM 节点
                const wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                // 获取当前的时间戳，用来当文件名
                // let time = new Date().getTime()
                let time = "导出店铺会员流水"
                try {
                    FileSaver.saveAs(
                    new Blob([wbout], { type: "application/octet-stream" }),
                    `${time}.xlsx`
                    ); //${time}是自己导出文件时的命名，随意
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, wbout)
                }
                return wbout
            },
        },
        created() {
            this.getData()
            this.getOrderstatistics()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>
<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label=""><el-button icon="Plus" @click="addModule" type="primary" v-show="hasPermission(this.$route.name,'Create')">新增</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号"></el-table-column> -->
            <el-table-column width="100" prop="id" label="套餐ID" ></el-table-column>
            <el-table-column min-width="200" prop="name" label="会员套餐名称" ></el-table-column>
            <el-table-column min-width="150" prop="booth_nums" label="展位个数" >
                <template #default="scope">
                    <span>{{scope.row.booth_nums}}个</span>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="100" label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.status">正常</el-tag>
                    <el-tag v-else type="danger">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column> -->
            <el-table-column label="操作" fixed="right" width="200">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleDialogClick(scope.row)" v-show="hasPermission(this.$route.name,'VipPackagePrice')">设置套餐价格</span>
                    <!-- <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span> -->
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <ly-dialog v-model="isDialogShow" :title="dialogTitle" width="60%"  :before-close="handleDialogClose">
            <div style="padding:10px 0 10px 0">
                <el-button icon="Plus" @click="handleEdit(null,'VipPackagePriceAdd')" type="primary" v-show="hasPermission(this.$route.name,'VipPackagePriceAdd')">新增</el-button>
            </div>
            <el-table :data="vipList" border stripe>
                <el-table-column min-width="150" prop="name" label="会员卡名称" ></el-table-column>
                <el-table-column min-width="100" prop="price" label="价格" ></el-table-column>
                <el-table-column min-width="100" prop="expireday" label="有效期" ></el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'VipPackagePriceEdit')" v-show="hasPermission(this.$route.name,'VipPackagePriceEdit')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'VipPackagePriceDelete')" v-show="hasPermission(this.$route.name,'VipPackagePriceDelete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <el-button @click="handleDialogClose">关闭</el-button>
            </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-module ref="addModuleFlag" @refreshData="getData"></add-module>
        <AddModuleVipPackage ref="addModuleVipPackageFlag" @refreshData="getVipPackageList"></AddModuleVipPackage>
    </div>

</template>
<script>
    import addModule from "./components/addModuleHouseVip";
    import AddModuleVipPackage from "./components/addModuleVipPackage";
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import LyDialog from "@/components/dialog/dialog";
    import {tcliveHousevip,tcliveHousevipDelete,tcliveHouseVipPackage,tcliveHouseVipPackageDelete} from '@/api/api'
    export default {
        components:{
            Pagination,
            addModule,
            LyDialog,
            AddModuleVipPackage
        },
        name:'tcHouseVipManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 20,
                },
                pageparm: {
                    page: 1,
                    limit: 20,
                    total: 0
                },
                statusList:[
                    {id:1,name:'是'},
                    {id:0,name:'否'}
                ],
                tableData:[],
                //dialog弹窗
                formData:{},
                isDialogShow:false,
                loadingSave:false,
                dialogTitle:"",
                vipList:[],
                currentVip:"",
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            addModule() {
                this.$refs.addModuleFlag.addModuleFn(null,'新增')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='edit') {
                    vm.$refs.addModuleFlag.addModuleFn(row,'编辑')
                }
                else if(flag=='delete') {
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        tcliveHousevipDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag == 'VipPackagePriceAdd'){
                    this.$refs.addModuleVipPackageFlag.addModuleFn(null,'新增',this.currentVip)
                }
                else if(flag == 'VipPackagePriceEdit'){
                    this.$refs.addModuleVipPackageFlag.addModuleFn(row,'编辑',this.currentVip)
                }
                else if(flag == 'VipPackagePriceDelete'){
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        tcliveHouseVipPackageDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getVipPackageList(vm.currentVip)
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 20
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                tcliveHousevip(this.formInline).then(res => {
                    this.loadingPage = false
                    if(res.code ==2000) {
                        this.tableData = res.data.data
                        this.pageparm.page = res.data.page;
                        this.pageparm.limit = res.data.limit;
                        this.pageparm.total = res.data.total;
                    }
                })
            },
            getVipPackageList(id){
                tcliveHouseVipPackage({vip_id:id,page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.vipList = res.data.data
                    }
                })
            },
            //dialog弹窗
            handleDialogClose(){
                this.isDialogShow = false
                this.formData = {}
                this.vipList = []
                this.currentVip = ""
            },
            handleDialogClick(row){
                this.currentVip = row.id
                this.getVipPackageList(row.id)
                this.dialogTitle = "设置套餐价格"
                this.isDialogShow = true
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }
        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },
        unmounted() {
              // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>



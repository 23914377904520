<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="昵称：">
                    <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable placeholder="昵称" @change="search" style="width:120px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:120px"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select size="default" v-model="formInline.status" placeholder="请选择" clearable style="width: 100px" @change="search">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提交时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
<!--                <el-form-item label=""><el-button size="small" @click="addModule" type="primary">新增</el-button></el-form-item>&lt;!&ndash;超管有此权限&ndash;&gt;-->
<!--                <el-form-item label=""><el-button size="small" @click="exportData" type="primary">导出</el-button></el-form-item>&lt;!&ndash;超管有此权限&ndash;&gt;-->
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'ConfirmContact')">
                    <el-button @click="handleMutipleDelete" type="primary" :disabled="multiple" >批量确认</el-button>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Delete')">
                    <el-button @click="handleDelete" type="danger" :disabled="multiple" >批量删除</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%"  @selection-change="handleSelectionChange">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column type="selection" width="55" align="center" disabled='false'></el-table-column>
            <el-table-column width="70" prop="id" label="ID"></el-table-column>
            <el-table-column min-width="70" prop="avatar" label="头像">
                    <template #default="scope">
                        <el-image :preview-src-list="[scope.row.userinfo.avatar]" :preview-teleported="true" :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 39px;height: 39px" :onerror="defaultImg"></el-image>
                    </template>
            </el-table-column>
            <el-table-column min-width="110" prop="userinfo.nickname" label="昵称"></el-table-column>
            <el-table-column min-width="120" prop="userinfo.mobile" label="账号"></el-table-column>
            <el-table-column width="60" prop="typename" label="类型"></el-table-column>
            <el-table-column width="130" prop="price" label="期望托管费用"></el-table-column>
            <el-table-column min-width="250" prop="position" label="摊位位置" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="170" prop="images" label="摊位实拍">
                <template #default="scope">
                    <span v-for="item in scope.row.images" :key="item">
                        <el-image v-if="isImage(item)" :preview-src-list="[item]" :preview-teleported="true" :src="item" style="width: 39px;height: 39px;margin-right:10px;"></el-image>
                        <el-icon size="40" v-else style="cursor: pointer;" @click="playVideo(item)"><VideoCameraFilled /></el-icon>
                    </span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="contacts" label="联系人"></el-table-column>
            <el-table-column width="120" prop="contact_mobile" label="联系方式"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" label="提交时间"></el-table-column>
            <el-table-column width="90" prop="statusname" label="状态"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'ConfirmContact')" v-show="hasPermission(this.$route.name,'ConfirmContact')" v-if="scope.row.status == 0">确认联系</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <lyvideo ref="moduleVideoPlayerFlag" v-if="isVideoDialogShow" @closed="isVideoDialogShow=false"></lyvideo>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import lyvideo from "@/components/lyvideo";
    import {dateFormats} from "@/utils/util";
    import {tcliveStallCustody,tcliveStallCustodyDelete,tcliveStallCustodyConfirmContact} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            lyvideo
        },
        name:'tcStallCustodyManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
                statusList:[
                    {id:0,name:'待联系'},
                    {id:1,name:'已联系'}
                ],
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                isVideoDialogShow:false,
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            isImage(url){
                const filename = url.substring(url.lastIndexOf('/')+1).toLowerCase();
                return /\.(gif|jpe?g|png)$/i.test(filename);
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return tcliveStallCustodyDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            /** 批量确认按钮操作 */
            handleMutipleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('确定已联系选中的用户了吗？', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return tcliveStallCustodyConfirmContact({ids:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            playVideo(src){
                let vm = this
                vm.isVideoDialogShow = true
                vm.$nextTick(()=>{
                    vm.$refs.moduleVideoPlayerFlag.moduleFn(src,'视频播放')
                })
            },
            addModule() {
                this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    vm.$refs.addModuleFlag.addModuleFn(row,'详情','1')
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }else if(flag=='delete') {
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        tcliveStallCustodyDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if (flag=='ConfirmContact'){
                    const ids = this.ids;
                    vm.$confirm('确定已联系该用户了吗？', "警告", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(function() {
                        return tcliveStallCustodyConfirmContact({ids:[row.id]}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    })
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                tcliveStallCustody(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
            this.getData()
        },
    }
</script>
<style scoped>

</style>
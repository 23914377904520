<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="table">
            <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column width="80" prop="user_id" label="用户ID"></el-table-column>
                <el-table-column width="80" prop="avatar" label="头像">
                    <template #default="scope">
                        <el-image :preview-src-list="[scope.row.avatar]" :preview-teleported="true" :src="scope.row.avatar ? scope.row.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column width="170" prop="nickname" label="昵称"></el-table-column>
                <el-table-column width="170" prop="mobile" label="手机号"></el-table-column>
                <el-table-column min-width="300" prop="message" label="反馈内容"></el-table-column>
                
               
                <el-table-column width="300" prop="create_datetime" label="提交时间"></el-table-column>
               
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
       
    </div>
</template>
<script>
    
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import {userfeeckback,UsersUsersExportexecl} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
   
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
          
        },
        name:'userManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                orderstatics:{
                    totalUsers: 0,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                identityList:[
                    {id:0,name:'普通用户'},
                    {id:1,name:'会员'},
                ],
                timers:[],
                tableData:[],
                isDetailDialogShow:false,
                isDetailStallDialogShow:false,
            }
        },

        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
           
           
            exportDataBackend() {
                var params = {
                    page: 1,
                    limit: 9999,
                }
                UsersUsersExportexecl(params).then(res => {
                     if(res.code ==2000) {
                         this.downloadFileURL(res.data.data)
                         //this.$message.warning(res.data.data)
                     }
                 })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                userfeeckback(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                         this.orderstatics.totalUsers = res.data.total
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },

        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>

<template>
    <div>
        <ly-dialog :title="loadingTitle" v-model="dialogVisible" width="50%" top="10px" center :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
            <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick" >
                <el-tab-pane label="店铺简介" :name="1">
                    <el-form :inline="false" :model="formData" ref="rulesForm" label-position="left" label-width="auto">
                        <el-form-item label="内容介绍：" prop="desc">
                            <el-input v-model="formData.desc" :rows="3" type="textarea" placeholder="请输入" :disabled="true" />
                        </el-form-item>
                        <el-form-item label="图片：">
                            <el-image v-for="item in formData.images_news" :preview-src-list="[item]" :preview-teleported="true" :src="item" style="width: 39px;height: 39px;margin-right:10px;"></el-image>
                        </el-form-item>
                        <el-form-item label="视频：">
                            <div v-for="video in formData.videos" :key="video">
                                <video :src="video" controls style="width:200px;height:200px;margin-right: 10px;"></video>
                            </div>
                        </el-form-item>
                        <el-form-item label="联系人：" prop="contacts">
                            {{formData.contacts}}
                        </el-form-item>
                        <el-form-item label="联系方式：" prop="contact_mobile">
                            {{formData.contact_mobile}}
                        </el-form-item>
                        <el-form-item label="微信号：" prop="weixin">
                            {{formData.weixin}}
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="产品列表" :name="2">
                    <el-table :height="500" border :data="productList" ref="tableref" v-loading="loadingPage" style="width: 100%" >
                        <el-table-column width="70" prop="id" label="产品ID"></el-table-column>
                        <el-table-column min-width="70" prop="cover" label="主图">
                                <template #default="scope">
                                    <el-image :preview-src-list="[scope.row.cover]" :preview-teleported="true" :src="scope.row.cover" style="width: 39px;height: 39px"></el-image>
                                </template>
                        </el-table-column>
                        <el-table-column min-width="180" prop="name" label="商品名称"></el-table-column>
                        <el-table-column width="130" prop="price" label="价格">
                            <template #default="scope">
                                <span>¥{{scope.row.price}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="180">
                            <template #default="scope">
                                <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            
            <template #footer>
                <el-button @click="handleClose">关闭</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {tcliveShopProduct,tcliveShopProductDelete} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    export default {
        components: {LyDialog},
        name: "addModuleShop",
        emits: ['refreshData','closed'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                formData:{
                    desc:'',
                    contacts:'',
                    contact_mobile:'',
                    weixin:'',
                    images:[],
                    videos:[],
                    images_news:[]
                },
                productList:[],
                loadingPage:false,
                activeName:1
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('closed')
            },
            handleTabClick(){

            },
            isImage(url){
                const filename = url.substring(url.lastIndexOf('/')+1).toLowerCase();
                return /\.(gif|jpe?g|png)$/i.test(filename);
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='delete') {
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        tcliveShopProductDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getProductList()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = deepClone(item)
                    this.formData.images_news = this.formData.images.filter(item => this.isImage(item))
                    this.formData.videos = this.formData.images.filter(item => !this.isImage(item))
                }
                this.getProductList()
            },
            getProductList(){
                this.loadingPage = true
                tcliveShopProduct({page:1,limit:999,shop_id:this.formData.id}).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                        this.productList = res.data.data
                     }
                })
            },
            submitData() {
                // this.$refs['rulesForm'].validate(obj=>{
                //     if(obj) {
                //         this.loadingSave=true
                //         let param = {
                //             ...this.formData
                //         }
                //         if(this.formData.id){
                //             apiSystemUserEdit(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }else{
                //             apiSystemUserAdd(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }

                //     }
                // })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(option) {
                // OSS.ossUploadProductImg(option);
            },
            imgUploadSuccess(res) {
                if (res) {
                    this.formData.img = res.url
                }
            },
        }
    }
</script>


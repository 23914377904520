<template>
    <div>
        <LyDialog :title="loadingTitle" v-model="dialogVisible" width="70%" center :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
            <el-table :height="400" border :data="shopList" ref="tableref" v-loading="loadingPage" style="width: 100%" >
                <el-table-column width="70" prop="id" label="店铺ID"></el-table-column>
                <el-table-column min-width="120" prop="name" label="店铺名称"></el-table-column>
                <el-table-column width="90" prop="typename" label="店铺类型"></el-table-column>
                <el-table-column min-width="180" prop="position" label="位置" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="90" prop="cover" label="店铺主图">
                        <template #default="scope">
                            <el-image :preview-src-list="[scope.row.cover]" :preview-teleported="true" :src="scope.row.cover" style="width: 39px;height: 39px"></el-image>
                        </template>
                </el-table-column>
                <el-table-column min-width="100" prop="business_hours" label="营业时间"></el-table-column>
                <el-table-column min-width="100" prop="expire_time" label="到期时间"></el-table-column>
                <el-table-column width="90" prop="statusname" label="状态"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')">查看详情</span>
                    </template>
                </el-table-column>
            </el-table>
            
            <template #footer>
                <el-button @click="handleClose">关闭</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </LyDialog>
        <ShopDetail ref="addModuleFlag" v-if="isDetailDialogShow" @closed="isDetailDialogShow=false"></ShopDetail>
    </div>
</template>

<script>
    import {tcliveShopList} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    import ShopDetail from "@/views/tcliveManage/components/addModuleShop"
    export default {
        components: {LyDialog,ShopDetail},
        name: "addModuleShop",
        emits: ['refreshData','closed'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                formData:{
                },
                shopList:[],
                loadingPage:false,
                isDetailDialogShow:false,
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('closed')
            },
            handleTabClick(){

            },
            handleEdit(row,flag) {
                let vm = this
                // if(flag=='detail') {
                //     this.$router.push({name:'tcShopManage',state:{mobile:this.formData.username}})
                // }
                if(flag=='detail') {
                    this.isDetailDialogShow = true
                    this.$nextTick(()=>{
                        vm.$refs.addModuleFlag.addModuleFn(row,'详情')
                    })
                }
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = deepClone(item)
                }
                this.getShopList()
            },
            getShopList(){
                this.loadingPage = true
                tcliveShopList({page:1,limit:999,creator:this.formData.id}).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                        this.shopList = res.data.data
                     }
                })
            },
            submitData() {
                // this.$refs['rulesForm'].validate(obj=>{
                //     if(obj) {
                //         this.loadingSave=true
                //         let param = {
                //             ...this.formData
                //         }
                //         if(this.formData.id){
                //             apiSystemUserEdit(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }else{
                //             apiSystemUserAdd(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }

                //     }
                // })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(option) {
                // OSS.ossUploadProductImg(option);
            },
            imgUploadSuccess(res) {
                if (res) {
                    this.formData.img = res.url
                }
            },
        }
    }
</script>


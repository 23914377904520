<template>
    <div>
        <LyDialog :title="loadingTitle" v-model="dialogVisible" width="70%" center :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
            <el-table :height="400" border :data="stallList" ref="tableref" v-loading="loadingPage" style="width: 100%" >
                <el-table-column width="70" prop="id" label="摊位ID"></el-table-column>
                <el-table-column width="90" prop="typename" label="摊位类型"></el-table-column>
                <el-table-column width="130" prop="name" label="摊位标题"></el-table-column>
                <el-table-column width="130" prop="price" label="价格">
                    <template #default="scope">
                        <span>¥{{scope.row.price}}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="250" prop="position" label="位置" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="150" prop="images" label="轮播图">
                    <template #default="scope">
                        <el-image v-for="item in scope.row.images" :preview-src-list="[item]" :preview-teleported="true" :src="item" style="width: 39px;height: 39px;margin-right:10px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="180" prop="create_datetime" label="发布时间"></el-table-column>
                <el-table-column width="90" prop="statusname" label="状态"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')">查看详情</span>
                    </template>
                </el-table-column>
            </el-table>
            
            <template #footer>
                <el-button @click="handleClose">关闭</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </LyDialog>
        <StallDetail ref="addModuleFlag" v-if="isDetailDialogShow" @closed="isDetailDialogShow=false"></StallDetail>
    </div>
</template>

<script>
    import {tcliveStallList} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    import StallDetail from "@/views/tcliveManage/components/addModuleStall"
    export default {
        components: {LyDialog,StallDetail},
        name: "addModuleStall",
        emits: ['refreshData','closed'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                formData:{
                },
                stallList:[],
                loadingPage:false,
                isDetailDialogShow:false,
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('closed')
            },
            handleTabClick(){

            },
            handleEdit(row,flag) {
                let vm = this
                // if(flag=='detail') {
                //     this.$router.push({name:'tcStallManage',state:{id:row.id}})
                // }
                if(flag=='detail') {
                    this.isDetailDialogShow = true
                    this.$nextTick(()=>{
                        vm.$refs.addModuleFlag.addModuleFn(row,'详情')
                    })
                }
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = deepClone(item)
                }
                this.getStallList()
            },
            getStallList(){
                this.loadingPage = true
                tcliveStallList({page:1,limit:999,creator:this.formData.id}).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                        this.stallList = res.data.data
                     }
                })
            },
            submitData() {
                // this.$refs['rulesForm'].validate(obj=>{
                //     if(obj) {
                //         this.loadingSave=true
                //         let param = {
                //             ...this.formData
                //         }
                //         if(this.formData.id){
                //             apiSystemUserEdit(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }else{
                //             apiSystemUserAdd(param).then(res=>{
                //                 this.loadingSave=false
                //                 if(res.code ==2000) {
                //                     this.$message.success(res.msg)
                //                     this.handleClose()
                //                     this.$emit('refreshData')
                //                 } else {
                //                     this.$message.warning(res.msg)
                //                 }
                //             })
                //         }

                //     }
                // })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(option) {
                // OSS.ossUploadProductImg(option);
            },
            imgUploadSuccess(res) {
                if (res) {
                    this.formData.img = res.url
                }
            },
        }
    }
</script>

